/* eslint-disable no-undef */

const lpcbSendAccessAbTesting = () => {

    const testTarget = document.querySelectorAll('a[data-testid], div[data-testid]');

    if (!testTarget || testTarget.length === 0) return;

    const url = params.ajax_url;
    const nonce = params.nonce;
    const postId = params.postId;

    testTarget.forEach(elem => {
        const testId = elem.dataset.testid;

        const param = new URLSearchParams();

        param.append('action', 'ab_test_access_count');
        param.append('nonce', nonce);
        param.append('testId', testId);
        param.append('postId', postId);

        fetch(url, {
            method: 'POST',
            body: param
        }).then(response => {
            return response.json();
        }).then(() => {
            // console.log(data);
        })

    })
}

const lpcbSendViewTargetAbTesting = () => {

    const testTarget = document.querySelectorAll('a[data-testid], div[data-testid]');

    
    if (!testTarget || testTarget.length === 0) return;

    const url = params.ajax_url;
    const nonce = params.nonce;

    testTarget.forEach(elem => {
        const testId = elem.dataset.testid;
        const testType = elem.dataset.testtype;

        const param = new URLSearchParams();

        param.append('action', 'ab_test_view_count');
        param.append('nonce', nonce);
        param.append('testId', testId);

        const observer = new IntersectionObserver((entries) => {
            for (const e of entries) {
                if (e.isIntersecting) {
                    fetch(url, {
                        method: 'POST',
                        body: param
                    }).then(response => {
                        return response.json();
                    }).then(() => {
                        // console.log(data);
                    })
                }
            }
        }, {});

        if (testType === 'link') {
            observer.observe(elem);
        } else {
            const target = elem.querySelector('input[type="submit"], button[type="submit"]');
            observer.observe(target);
        }
        
    })
}

const lpcbSendClickTargetAbTesting = () => {

    const testTarget = document.querySelectorAll('a[data-testid], div[data-testid], div[data-testid]');

    if (!testTarget || testTarget.length === 0) return;

    const url = params.ajax_url;
    const nonce = params.nonce;

    testTarget.forEach(elem => {
        const testId = elem.dataset.testid;

        const param = new URLSearchParams();

        param.append('action', 'ab_test_click_count');
        param.append('nonce', nonce);
        param.append('testId', testId);

        let target;
        let event;
        let clicked = false;

        const testType = elem.dataset.testtype;

        if (testType === 'link') {
            target = elem;
            event = 'click';
        } else {
            target = elem.querySelector('input[type="submit"], button[type="submit"]');
            event = 'submit';
        }

        target.addEventListener(event, () => {

            if (!clicked) {
                fetch(url, {
                    method: 'POST',
                    body: param
                }).then(response => {
                    return response.json();
                }).then(() => {
                    // console.log(data);
                });

                clicked = true;
            }
        });
    })
}

window.onload = lpcbSendAccessAbTesting();
window.onload = lpcbSendViewTargetAbTesting();
window.onload = lpcbSendClickTargetAbTesting();